import { NotificationOutlined } from "@ant-design/icons";
import Icon from "@shared/ui/icons/Icon";
import Popup from "@shared/ui/Popup";
import Vbox from "@shared/ui/layouts/Vbox";
import Notification from "@shared/ui/Notification";
import { Badge } from "antd";
import makeRequest from "@shared/api/makeRequest";
import { useEffect, useState, useRef, useCallback } from "react";
import Pagination from "@shared/ui/Pagination";
import styled from "styled-components";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";


const ListContainer = styled(Vbox)`
    max-height: 400px;
    overflow-y: scroll;

    /* Стили скролла */
    &::-webkit-scrollbar {
        width: 4px; // Ширина полосы прокрутки
    }

    &::-webkit-scrollbar-track {
        background: #e0e0e0; // Цвет фона трека
        border-radius: 4px; // Скругление трека
    },

    &::-webkit-scrollbar-thumb {
        background: #888; // Цвет бегунка
        border-radius: 4px; // Скругление бегунка
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555; // Цвет бегунка при наведении
    }
`


const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const messagesContainerRef = useRef(null);
    const dummyRef = useRef(null);
    const heightRef = useRef(0);

    const scrollToBottom = useCallback(scrollToBottomCallback, []);
    const restoreScrollPosition = useCallback(restoreScrollPositionCallback, [scrollToBottom]);

    useEffect(fetchNotifications, [currentPage]);
    useEffect(() => {
        restoreScrollPosition(heightRef.current);
    }, [notifications, restoreScrollPosition]);

    return <Badge size="small">
        <Popup arrow={false} content={<Vbox>
            <ListContainer ref={messagesContainerRef} onScroll={handleScroll} gap="10px">
                {notifications?.length < 1 && "Нет уведомлений"}
                {notifications.map(({text, date}) => <Notification date={date}>{text}</Notification>)}
            </ListContainer>
        </Vbox>}>
            <Icon icon={NotificationOutlined} />
        </Popup>
    </Badge>

    function fetchNotifications() {
        (async () => {
            try {
                const {data, total, total_pages} = await makeRequest(`/api/v1/notify/get_history?page=${currentPage}`, { method: "GET" });

                setTotalPages(total_pages);
                setNotifications(data);
            } catch(e) {
                console.log(e)
            }
        })()
    }

    function handleScroll() {
        const container = messagesContainerRef.current;

        if (container?.scrollTop === 0) {
            const prevHeight = container.scrollHeight;
            heightRef.current = prevHeight;
            fetchNotifications();
        }
    }

    function restoreScrollPositionCallback(prevHeight) {
        const container = messagesContainerRef.current;

        if (container?.scrollTop === 0) {
            container.scrollTop = container.scrollHeight - prevHeight;
        } else {
            scrollToBottom();
        }
    }

    function scrollToBottomCallback() {
        if (dummyRef.current) {
            dummyRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }
}

export default Notifications;