import PageWrapper from "@shared/ui/layouts/PageWrapper";
import makeRequest from "@shared/api/makeRequest";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Info from "@shared/ui/Info";
import Vbox from "@shared/ui/layouts/Vbox";
import GlassCard from "@shared/ui/GlassCard";
import Hbox from "@shared/ui/layouts/Hbox";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import styled from "styled-components";
import {TwoColumns} from "@shared/ui/containers/ListContainers";
import ReceiptsBlock from "./elements/ReceiptsBlock";
import Chat from "@features/Chat";
import getRoomsIdsApi from "@shared/api/services/chat/getRoomsIdsApi";
import {useAuth} from "@shared/AuthContext";
import Timeline from "@shared/ui/Timeline";
import convertUTCToLocal from "@shared/helpers/convertUTCToLocal";
import AdminActions from "./elements/AdminActions";
import MerchantActions from "./elements/MerchantActions";
import Link from "@shared/ui/Link";
import TraderActions from "./elements/TraderActions";
import SupportActions from "./elements/SupportActions";
import getUserInfoApi from "@shared/api/services/admin/getUserInfoApi";
import TraderLink from "./elements/TraderLink";
import ShopLink from "./elements/ShopLink";


const actions = {
    trader: TraderActions,
    support: SupportActions,
    merchant: MerchantActions,
    admin: AdminActions
}

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: 5fr 1fr;
    gap: 10px;
    word-break: break-word;

    @media(max-width: ${props => props.theme.mediaSizes.small}) {
        grid-template-columns: 1fr;
    }
`

const ReqsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
`

const allRoles = ["admin", "merchant", "trader", "support"];


const fields = {
    main_info: [
        { title: "ID ордера", getValue: (orderData) => orderData?.id, roles: allRoles },
        { title: "Дата создания ордера", getValue: (orderData) => convertUTCToLocal(orderData?.create_date), roles: allRoles },
        { title: "ID ордера у мерчанта", getValue: (orderData) => orderData.integration?.external_order_id, roles: ["admin", "merchant", "support"] },
        { title: "ID клиента у мерчанта", getValue: (orderData) => orderData.customers?.external_id, roles: ["admin", "merchant", "support"] },
        { title: "ID клиента", getValue: (orderData) => orderData.customers?.id, roles: ["admin", "merchant", "support"] },
        { title: "Трейдер", getValue: (orderData) => <TraderLink trader_id={orderData?.trader_id} />, roles: ["admin", "support"] },
        { title: "Магазин", getValue: (orderData) => <ShopLink shop_id={orderData?.shop_id} />, roles: ["admin", "support", "merchant"] }
    ],
    requisites: [
        { title: "Валюта", getValue: (orderData) => orderData?.currency, roles: allRoles },
        { title: "Банк", getValue: (orderData) => orderData?.bank_name, roles: allRoles },
        { title: "Курс", getValue: (orderData) => `${orderData?.rate} ${orderData?.currency}`, roles: allRoles },
        { title: "ФИО", getValue: (orderData) => orderData?.requisite?.fio, roles: allRoles },
        { title: "Сумма в фиате", getValue: (orderData) => orderData?.amount, roles: allRoles },
        { title: "Номер", getValue: (orderData) => orderData?.requisite?.requisite_number, roles: allRoles },
        { title: "Сумма в USDT", getValue: (orderData) => orderData?.usdt_amount, roles: allRoles },
    ],
    status: [
        { title: "Reason", getValue: (orderData) => orderData?.reason?.label, roles: allRoles },
        { title: "Status", getValue: (orderData) => orderData?.status?.label, roles: allRoles },
        { title: "Колбек", getValue: (orderData) => orderData?.integration?.callback_url, roles: ["admin", "merchant", "support"] },
        { title: "Описание", getValue: (orderData) => orderData?.status?.description || "-", roles: allRoles },
    ]
}

const Order = ({}) => {
    const {order_id} = useParams();
    const {user} = useAuth();
    const [orderData, setOrderData] = useState({});
    const [receipts, setReceipts] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [traderData, setTraderData] = useState({});

    useEffect(fetchOrderData, []);
    useEffect(fetchReceiptsBlock, []);
    useEffect(fetchRoomsIds, []);
    useEffect(fetchTraderData, []);

    const callbacks = useMemo(getCallbacks, [orderData]);

    const Actions = actions[user?.role] || <></>;

    return <PageWrapper title="Детали ордера">
        <Vbox gap="10px">
            <GlassCard>
                <MainContainer>
                    <TwoColumns>
                        <ElementCard>
                            <Text weight="bold">Основная информация</Text>
                            {fields.main_info.map(({title, getValue, roles}) => roles.includes(user?.role) && <Info title={title} value={getValue(orderData)} />)}
                        </ElementCard>
                        <Vbox gap="10px">
                            <ElementCard>
                                <Text weight="bold">Реквизиты</Text>
                                <Hbox>
                                    {fields.requisites.slice(0, 3).map(({title, getValue, roles}) => roles.includes(user?.role) && <Info title={title} value={getValue(orderData)} />)}
                                </Hbox>
                                <ReqsContainer>
                                    {fields.requisites.slice(3, fields.requisites.length + 1).map(({title, getValue, roles}) => roles.includes(user?.role) && <Info title={title} value={getValue(orderData)} />)}
                                </ReqsContainer>
                            </ElementCard>
                            <ElementCard>
                                <Text weight="bold">Статус</Text>
                                {fields.status.map(({title, getValue, roles}) => roles.includes(user?.role) && <Info title={title} value={getValue(orderData)} />)}
                            </ElementCard>
                        </Vbox>
                    </TwoColumns>
                    <Vbox gap="10px">
                        {<Actions orderData={orderData} onSuccess={() => {fetchOrderData(); fetchReceiptsBlock(); fetchRoomsIds()}} />}
                        {callbacks?.length > 0 && <ElementCard>
                            <Text weight="bold">История</Text>
                            <Timeline items={callbacks} />
                        </ElementCard>}
                    </Vbox>
                </MainContainer>
            </GlassCard>
            <ReceiptsBlock receipts={receipts} />
            <TwoColumns>
                {rooms.map(({room_id, username}) => <Chat room_id={room_id} username={username || "Поддержка"} />)}
            </TwoColumns>
        </Vbox>
    </PageWrapper>

    function fetchOrderData() {
        (async () => {
            try {
                const data = await makeRequest(`/api/v1/orders/transactions/${order_id}`, { method: "GET" });
                
                setOrderData(data);
            } catch(e) {
                console.log(e);
            }
        })();
    }

    function fetchReceiptsBlock() {
        (async () => {
            try {
                const receipts = await makeRequest(`/api/v1/orders/transactions/${order_id}/receipts/get`, { method: "GET" });

                setReceipts(receipts)
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function fetchRoomsIds() {
        (async () => {
            try {
                const {rooms} = await getRoomsIdsApi(order_id);

                setRooms(rooms);
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function getCallbacks() {
        return orderData.callbacks
            ?.map(({reason, created_at, ...props}) => ({
                children: reason, created_at, ...props
            }))
            .sort((el1, el2) => new Date(el1.created_at) - new Date(el2.created_at))
    }

    function fetchTraderData() {
        (async () => {
            if(orderData?.trader_id) return;

            try {
                const traderData = await getUserInfoApi(orderData?.trader_id);

                setTraderData(traderData);
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

export default Order;