// src/components/sections/Section1.js
import React from 'react';
import {ThreeColumns} from "@shared/ui/containers/ListContainers";
import PageWrapper from "@shared/ui/layouts/PageWrapper";  
import TwoFactorAuth from '@features/TwoFactorAuth';
import TradingSettings from './elements/TradingSettings';
import TelegramLogin from '@features/TelegramLogin';
import InvitePeople from '@features/InvitePeople';
import Vbox from "@shared/ui/layouts/Vbox";


const inviteRoles = [
    {label: 'Трейдер', value: 'trader'},
    {label: 'Мерчант', value: 'merchant'},
    {label: 'Агент', value: 'agent'},
    {label: 'Саппорт', value: 'support'},
    {label: 'Каскад', value: 'cascade'},
    {label: 'Аналитик', value: 'analyst' }
];


const Settings = () => {
    return (
        <PageWrapper title="Настройки">
            <ThreeColumns>
                <Vbox gap="10px">
                    <TwoFactorAuth />
                    <TelegramLogin bot="many_push_bot" />
                </Vbox>
                <Vbox>
                    <InvitePeople roles={inviteRoles} />
                </Vbox>
                <Vbox>
                    <TradingSettings />
                </Vbox>
            </ThreeColumns>
        </PageWrapper>
    );
};

export default Settings;