import {ThreeColumns} from "@shared/ui/containers/ListContainers";
import getBalanceApi from "@shared/api/services/getBalanceApi";
import InfoCard from '@shared/ui/InfoCard';
import Text from "@shared/ui/Text";
import React, { useState, useEffect } from 'react';
import GlassCard from "@shared/ui/GlassCard";


const data1 = [
    { name: 'Day 1', value: 1000 },
    { name: 'Day 2', value: 1300 },
    { name: 'Day 3', value: 4000 },
    { name: 'Day 4', value: 1200 },
    { name: 'Day 5', value: 1400 },
    { name: 'Day 6', value: 2000 },
    { name: 'Day 7', value: 1700 },
    { name: 'Day 8', value: 7000 },
];

const data2 = [
    { name: 'Day 1', value: 7000 },
    { name: 'Day 2', value: 4000 },
    { name: 'Day 3', value: 4000 },
    { name: 'Day 4', value: 1200 },
    { name: 'Day 5', value: 1400 },
    { name: 'Day 6', value: 2000 },
    { name: 'Day 7', value: 1700 },
    { name: 'Day 8', value: 1000 },
];


const Platform = () => {
    const [balance, setBalance] = useState(0);
    const [profit, setProfit] = useState(0);

    useEffect(fetchBalance, []);

    return <GlassCard>
        <Text weight="bold">Основные балансы</Text>
        <ThreeColumns>
            <InfoCard data={data1} currency="USDT" title="Баланс площадки" value={balance} />
            <InfoCard data={data2} currency="USDT" title="Профит площадки" value={profit} />
            <InfoCard data={data2} currency="USDT" title="TRX баланс" value={profit} />
        </ThreeColumns>
    </GlassCard>

    function fetchBalance() {
        (async () => {
            try {
                const {total_balance, user_balance} = await getBalanceApi();
            
                setBalance(total_balance);
                setProfit(user_balance?.total_balance);
            } catch(e) {
                console.log(e)
            }
        })();
    }
}

export default Platform;