import Table from "@shared/ui/Table";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import Text from "@shared/ui/Text";
import Select from "@shared/ui/Select";
import GlassCard from "@shared/ui/GlassCard"
import { useEffect, useMemo, useState } from "react";
import makeRequest from "@shared/api/makeRequest";
import RangePicker from "@shared/ui/RangePicker";
import useSearchParams from "@shared/hooks/useSearchParams";
import dayjs from "dayjs";
import CopyableText from "@shared/ui/CopyableText"; 


const MerchantsStats = () => {
    const {searchParams, updateSearchParams} = useSearchParams();
    const [merchants, setMerchants] = useState([]);
    const [shops, setShops] = useState([]);
    const [shopId, setShopId] = useState("");
    const shopsOptions = useMemo(getShopsOptions, [shops]);
    const [merchantId, setMerchantId] = useState("");
    const merchantsOptions = useMemo(getMerchantOptions, []);
    const [currencies, setCurrencies] = useState([]);
    const [currencyId, setCurrencyId] = useState("");
    const currenciesOptions = useMemo(getCurrenciesOptions, [currencies]);
    const [dateFrom, setDateFrom] = useState(dayjs().toISOString());
    const [dateTo, setDateTo] = useState(dayjs().toISOString());
    const [stats, setStats] = useState({});

    useEffect(fetchShops, [merchantId, currencyId]);
    useEffect(fetchCurrencies, []);
    useEffect(fetchMerchants, [currencyId]);
    useEffect(fetchStats, [merchantId, shopId, currencyId, dateFrom, dateTo]);

    return <Vbox gap="10px">
        <Hbox customJust="start" gap="10px">
            <GlassCard width="fit-content">
                <Text weight="bold">Трафик</Text>
                 <div><RangePicker startDate={dayjs(dateFrom)} endDate={dayjs(dateTo)} label="Период" onChange={(startDate, endDate) => {
                    setDateFrom(startDate?.toISOString());
                    setDateTo(endDate?.toISOString());
                }} /></div>
                <Select value={currencyId} options={currenciesOptions} onChange={setCurrencyId} label="Фильтр по валюте" />
                <Select value={merchantId || ""} options={[{value: "", label: "Все мерчанты"}, ...merchants]} onChange={setMerchantId} label="Фильтр по мерчанту" />
                <Select value={shopId || ""} options={[{value: "", label: "Все магазины"}, ...shops]} onChange={setShopId} label="Фильтр по магазину" />
                
                <Text>Объем - {stats.turnover?.toFixed(2) || 0} {currencyId}</Text>
                <Text>Конверсия - {100 - (stats.conversion?.toFixed(0) || 100)} %</Text>
                <Text>Кешбек агента - {stats.income?.toFixed(0) || 0} {currencyId}</Text>
            </GlassCard>
        </Hbox>
        <MerchantsTable />
    </Vbox>

    function getShopsOptions() {
        const options = shops.map(({shop_id, shop_name}) => ({label: shop_name, value: shop_id}));
        setShopId(options[0]?.value);

        return options;
    }

    function getMerchantOptions() {
        const options = merchants.map(({username, user_id}) => ({label: username, value: user_id}));
        setMerchantId(options[0]?.value);

        return options;
    }

    function getCurrenciesOptions() {
        const options = currencies.map(({currency}) => ({label: currency, value: currency}));
        setCurrencyId(options[0]?.value);

        return options;
    }

    function fetchShops() {
        (async () => {
            try {
                if(!merchantId || !currencyId) return;

                const shops = await makeRequest(`/api/v1/core/account/stats/merchants/get_shops_by_id?merchant_id=${merchantId}&currency=${currencyId}`, { method: "GET" });
                const shopsOptions = shops.map(({shop_id, shop_name}) => ({value: shop_id, label: shop_name}));

                setShops(shopsOptions);
                setShopId(shopsOptions[0]?.value)
            } catch(e) {
                console.log(e);
            }
        })();
    }

    function fetchMerchants() {
        (async () => {
            try {
                const merchants = await makeRequest(`/api/v1/core/account/stats/merchants/get_invited?currency=${currencyId}`, { method: "GET" });

                const merchantsOptions = merchants.map(({user_id, username}) => ({value: user_id, label: username}));

                setMerchants(merchantsOptions);
                setMerchantId(merchantsOptions[0]?.value);
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function fetchCurrencies() {
        (async () => {
            try {
                const currencies = await makeRequest(`/api/v1/core/account/stats/merchants/get_currency`, { method: "GET" });
            
                setCurrencies(currencies);
            } catch(e) {
                console.log(e);
            }
        })();
    }

    function fetchStats() {
        (async () => {
            try {
                const {turnover, income, conversion} = await makeRequest(`/api/v1/core/account/stats/merchants/get_statistic?currency=${currencyId}${merchantId ? `&merchant_id=${merchantId}` : ""}${shopId ? `&shop_id=${shopId}` : ""}${dateFrom ? `&from_date=${dateFrom}` : ""}${dateTo ? `&to_date=${dateTo}` : ""}`, { method: "GET" })
            
                setStats({turnover, income, conversion});
            } catch(e) {
                console.log(e)
            }
        })();
    }
}

function MerchantsTable() {
    const [merchants, setMerchants] = useState([]);
    const [currencyId, setCurrencyId] = useState("");
    const [currencies, setCurrencies] = useState([]);

    useEffect(fetchMerchants, [currencyId]);
    useEffect(fetchCurrencies, []);

    const Value = ({children}) => `${children?.toFixed(2) || 0} ${currencyId}`;

    const columns = [
        { title: "ID пользователя", dataIndex: "user_id", render: (value) => <CopyableText text={value} />, width: "1px" },
        { title: "Оборот за день", dataIndex: "day_turnover", render: (value) => <Value>{value}</Value> },
        { title: "Оборот за неделю", dataIndex: "week_turnover", render: (value) => <Value>{value}</Value> },
        { title: "Оборот за месяц", dataIndex: "month_turnover", render: (value) => <Value>{value}</Value> },
        { title: "Доход за день", dataIndex: "day_income", render: (value) => <Value>{value}</Value> },
        { title: "Доход за неделю", dataIndex: "week_income", render: (value) => <Value>{value}</Value> }, 
        { title: "Доход за месяц", dataIndex: "month_income", render: (value) => <Value>{value}</Value> }, 
    ]

    return <GlassCard>
        <Text weight="bold">Мерчанты</Text>
        <Hbox>
            <Select options={currencies} value={currencyId} onChange={setCurrencyId} />
        </Hbox>
        
        <Table
            dataSource={merchants}
            columns={columns}
        />
    </GlassCard>

    function fetchMerchants() {
        (async () => {
            try {
                const merchants = await makeRequest(`/api/v1/core/account/stats/merchants/get_table?page=1&currency=${currencyId}`, { method: "GET" });

                setMerchants(merchants || []);
            } catch(e) {
                console.log(e)
            }
        })();
    }

    function fetchCurrencies() {
        (async () => {
            try {
                const currencies = await makeRequest(`/api/v1/core/account/stats/merchants/get_currency`, { method: "GET" });
                const currenciesOptions = currencies.map(({currency}) => ({label: currency, value: currency}));

                setCurrencies(currenciesOptions);

                if(currenciesOptions?.length) {
                    setCurrencyId(currenciesOptions[0]?.value)
                }
            } catch(e) {
                console.log(e);
            }
        })();
    }
}

export default MerchantsStats;