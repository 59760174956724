import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import AmountInput from "./elements/AmountInput";
import { useForm } from "react-hook-form";
import SimpleButton from "@shared/ui/buttons/SimpleBtn";
import CustomerIdInput from "./elements/CustomerIdInput";
import PaymentMethodDropDown from "./elements/PaymentMethodDropDown";
import ControllerInput from "./elements/ControllerInput";
import makeRequest from "@shared/api/makeRequest";
import handleAsyncNotification from "@shared/helpers/handleAsyncNotification";
import { useEffect, useState } from "react";
import getAllCurrenciesApi from "@shared/api/services/getAllCurrenciesApi";


const CreateOrderFromShopModal = ({shopData, closeModal}) => {
    const { handleSubmit, control, formState: { errors } } = useForm();
    const {fiat_currency_id, id: shop_id, } = shopData;
    const [currencies, setCurrencies] = useState([]);

    useEffect(fetchCurrencies, []);

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Vbox gap="10px">
            <AmountInput control={control} error={errors.amount?.message} />
            <ControllerInput control={control} defaultValues={{}} errors={errors} label="ID клиента" name="shopcastid" />
            <PaymentMethodDropDown control={control} error={errors.payment_method?.message} currency_id={fiat_currency_id} />
            <ControllerInput control={control} defaultValues={{}} errors={errors} label="ID внешнего ордера" name="external_order_id" />
            <ControllerInput control={control} defaultValues={{}} errors={errors} label="callback_url" name="callback_url" />
            <ControllerInput control={control} defaultValues={{}} errors={errors} label="callback_method" name="callback_method" />
            <ControllerInput control={control} defaultValues={{}} errors={errors} label="return_url" name="return_url" />
            <SimpleButton type="submit" main>Создать ордер</SimpleButton>
        </Vbox>
    </form>

    function onSubmit({amount, payment_method, external_order_id, callback_url, callback_method, return_url, shopcastid}) {
        (async () => {
            try {
                await handleAsyncNotification(async () => {
                    await makeRequest("/api/v1/orders/shop/payin/create/manual", { 
                        method: "POST",
                        body: {
                            shop_id,
                            amount,
                            currency: currencies.find(({id}) => id === fiat_currency_id)?.currency,
                            customer: {
                                shopcastid
                            },
                            payment: {
                                payment_method
                            },
                            integration: {
                                external_order_id,
                                callback_method,
                                callback_url,
                                return_url
                            }
                        }
                    });
                    closeModal();
                }, {
                    successText: "Ордер успешно создан",
                    loadingText: "Создание ордера",
                    errorText: "Ошибка создания ордера"
                });
            } catch(e) {
                console.log(e)
            }
        })()
    }

    function fetchCurrencies() {
        (async () => {
            try {
                const currencies = await getAllCurrenciesApi();

                setCurrencies(currencies);
            } catch(e) {
                console.log(e)
            }
        })();
    }
}

export default CreateOrderFromShopModal;