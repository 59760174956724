import GlassCard from "@shared/ui/GlassCard";
import ElementCard from "@shared/ui/ElementCard";
import Text from "@shared/ui/Text";
import Vbox from "@shared/ui/layouts/Vbox";
import Hbox from "@shared/ui/layouts/Hbox";
import Info from "@shared/ui/Info";
import makeRequest from "@shared/api/makeRequest";
import { useEffect, useState } from "react";
import Table from "@shared/ui/Table";
import styled from "styled-components";
import {ThreeColumns, TwoColumns} from "@shared/ui/containers/ListContainers";


const columns = [
    { title: "Пользователь", dataIndex: "username" },
    { title: "Общий доход", dataIndex: "total_income" }
]

const TableWrapper = styled.div`
  width: 100%; /* Ограничение ширины контейнера */
  overflow-x: auto; /* Горизонтальная прокрутка, если контент выходит за пределы */
`;

const IncomeTurnoverColumns = [
    { title: "За последние 7 дней", dataIndex: "last_7_days" },
    { title: "За последние 30 дней", dataIndex: "last_30_days" },
    { title: "За предпоследние 7 дней", dataIndex: "prev_7_days" },
    { title: "За предпоследние 30 дней", dataIndex: "prev_30_days" },
    { title: "За сегодня", dataIndex: "today" },
    { title: "За вчера", dataIndex: "yesterday" },
]
 
const AllStats = () => {
    const [topAgents, setAgents] = useState([]);
    const [topTraders, setTraders] = useState([]);
    const [topMerchants, setMerchants] = useState([]);
    const [income, setIncome] = useState([]);
    const [turnover, setTurnover] = useState([]);

    useEffect(fetchStats, []);
    useEffect(fetchIncomeAndTurnover, []);

    return <Vbox gap="10px">
        <ThreeColumns>
            <GlassCard>
                <Text>Топ агентов</Text>
                <TableWrapper>
                    <Table
                        dataSource={topAgents}
                        columns={columns}
                    />
                </TableWrapper>
            </GlassCard>
            <GlassCard>
                <Text>Топ трейдеров</Text>
                <TableWrapper>
                    <Table
                        dataSource={topTraders}
                        columns={columns}
                    />
                </TableWrapper>
            </GlassCard>
            <GlassCard>
                <Text>Топ мерчантов</Text>
                <TableWrapper>
                    <Table
                        dataSource={topMerchants}
                        columns={columns}
                    />
                </TableWrapper>
            </GlassCard>
        </ThreeColumns>
        <TwoColumns>
            <GlassCard>
                <Text weight="bold">Доход</Text>
                <Vbox gap="10px">
                    <Info title="Сегодня" value={income.today} />
                    <Info title="Вчера" value={income.yesterday} />
                    <Info title="Последние 7 дней" value={income.last_7_days} />
                    <Info title="Предпоследние 7 дней" value={income.prev_7_days} />
                    <Info title="Последние 7 дней" value={income.last_30_days} />
                    <Info title="Предпоследние 7 дней" value={income.prev_30_days} />
                </Vbox>
            </GlassCard>
            <GlassCard>
                <Text weight="bold">Оборот</Text>
                <Vbox gap="10px">
                    <Info title="Сегодня" value={turnover.today} />
                    <Info title="Вчера" value={turnover.yesterday} />
                    <Info title="Последние 7 дней" value={turnover.last_7_days} />
                    <Info title="Предпоследние 7 дней" value={turnover.prev_7_days} />
                    <Info title="Последние 7 дней" value={turnover.last_30_days} />
                    <Info title="Предпоследние 7 дней" value={turnover.prev_30_days} />
                </Vbox>
            </GlassCard>
        </TwoColumns>
    </Vbox>

    function fetchStats() {
        (async () => {
            try {
                const {traders, merchants, agents} = await makeRequest("/api/v1/core/account/stats/get_best_guys", {
                    method: "GET"
                });
        
                setTraders(traders);
                setMerchants(merchants);
                setAgents(agents);
            } catch(e) {
                console.log(e)
            }
        })()
    }

    function fetchIncomeAndTurnover() {
        (async () => {
            try {
                const {income, turnover} = await makeRequest("/api/v1/core/account/stats/get_income_and_turnover", {
                    method: "GET"
                });
        
                setIncome(income);
                setTurnover(turnover);
            } catch(e) {
                console.log(e)
            }
        })()
    }
}

export default AllStats;